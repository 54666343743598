*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 16px;
	width: 100vw;
}

html,
body {
	min-height: 100vh;
	position: relative;
}

body {
	font-size: 1rem;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	background: #F8F9FA;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;

	@include media-breakpoint-down(md) {
		padding-bottom: 52px;
	}

	@include media-breakpoint-down(sm) {
		padding-bottom: 50px;
	}

	@media (max-width: 359.98px) {
		padding-bottom: 32px;
	}

    &.no-scroll {
        height: 100%;
    }

	&.fancybox-active,
	&.no-scroll {
		overflow: hidden;
	}

	// Theme colors CSS here

	// &[data-theme="default"] {
	// 	.primary-backgroud-color {
	// 		background: $primaryColor;
	// 	}
	// 	// .secondary-backgroud-color {
	// 	// 	background: $secondaryColor;
	// 	// }
	// }

	// &[data-theme="blue"] {
	// 	.primary-backgroud-color {
	// 		background: blue;
	// 	}
	// 	.secondary-backgroud-color {
	// 		background: darkblue;
	// 	}
	// }
}

.content-block {
	@include content-block;

	h3,
	h4,
	h5,
	h6 {
		font-weight: 500;
		margin-bottom: 1rem;
		margin-top: 1rem;
	}

	p,
	ul,
	ol {
		color: $darkTextColor;
		font-size: 14px;

		& + p,
		& + ul {
			margin-top: 1rem;
		}
	}

	ul,
	ol {
		padding-left: 1rem;
	}

	li::marker {
		font-size: 0.8rem;
	}

	hr {
		background-color: $primaryLightColor;
		opacity: 1;
	}

	&-text {
		a {
			color: $linksColor;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&.no-hover-shadow {
		&:hover {
			box-shadow: none;
		}
	}
}

.content-block-p24 {
	@include content-block(1.5rem);
}

.mobile-no-gutters {

	// Add to .container to apply class
	@include media-breakpoint-down(md) {
		padding-left: 0;
		padding-right: 0;
		overflow: hidden;

		main > .row {
			--bs-gutter-x: 0;
		}

		.sc-page-title,
		nav[aria-label="breadcrumb"] {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		.mobile-no-radius {
			border-radius: 0;
		}
	}
}

@include media-breakpoint-down(md) {
	.sc-page-title {
		margin-bottom: 0;

		h1 {
			font-size: 1.25rem;
		}
	}
}

.red,
.required {
    color: $redColor !important;
}

.blue-link {
    color: $linksColor;
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		color: $linksColor;
	}
}

.light-text {
	color: $lightTextColor;
}

.dark-text {
	color: $darkTextColor;
}

.br-2 {
	border-radius: 0.125rem !important;
}

.br-4 {
	border-radius: 0.25rem !important;
}

.br-6 {
	border-radius: 0.375rem !important;
}

.br-8 {
	border-radius: 0.5rem !important;
}

.br-12 {
	border-radius: 0.75rem !important;
}

.error_style {
    background-color: #FFEEF5 !important;
    border: 1px solid #FE4D97 !important;
    transition: background-color $mainTransition, border $mainTransition;

	& + label {
	    color: $redColor !important;
	}
}

.sc-no-stock {
	filter: grayscale(100%);
}

@media (min-width: $break-xxl) {
	.container,
	.container-fluid,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl,
	.modal-dialog-1110 {
		max-width: 1600px !important;
	}
}

// Dropdown
.sc-dropdown-box {
	display: inline-block;
	position: relative;

	&.active {
		cursor: pointer;

		.sc-dropdown {
			opacity: 1;
			margin-top: 0;
			z-index: 10000;
			visibility: visible;

			&-toggle {
				color: $linksColor;
				text-decoration: underline;
				cursor: pointer;
				z-index: 2;

				&::after {
					transform: rotate(180deg);
					background: $linksColor;
				}
			}
		}
	}
}

.sc-dropdown {
	position: absolute;
	top: calc(100% - 0.5rem);
	left: 50%;
	right: auto;
	transform: translate(-50%, 0);
	min-width: 200px;
	opacity: 0;
	z-index: -1;
	margin-top: 20px;
	padding-top: 20px;
	transition: opacity $mainTransition 0.1s, margin-top $mainTransition 0.1s;
	visibility: hidden;
	cursor: default;

	&-inner {
		background: #FFFFFF;
		box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
	}

	&-toggle {
		cursor: pointer;

		&::after {
			content: '';
			display: inline-block;
			mask: url("../img/sprite.svg#include--top-dropdown-toggle");
			mask-size: cover;
			width: 12px;
			height: 12px;
			margin-left: 0.25rem;
			background: inherit;
			transition: transform $mainTransition;
		}
	}

	&::after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		border: 10px solid transparent;
		border-top: 10px solid #FFFFFF;
		transform: rotate(180deg);
	}
}

// Timer
// .sc-timer-inner {
// 	padding: 0.25rem 0.5rem;
// 	background: $redColor;
// 	color: #FFFFFF;
// }

// .sc-timer-inner>*.animated {
// 	animation: seconds 1s infinite;
// }

// .sc-timer-number {
// 	width: 20px;
// 	text-align: center
// }

// .sc-timer-delimiter {
// 	padding: 0 2px
// }

// Buy in one click
.sc-buy-one-click {
	&-title {
		white-space: nowrap;
		padding-right: 0.75rem;
	}

	.form-control {
		padding: 0.5rem 1rem;
		height: 48px !important;
		border: $mainBorder;
	}

	.input-group-btn {
		margin-left: 0 !important;
	}

	.sc-btn {
		width: 48px !important;
		height: 48px !important;
		box-shadow: none;
		filter: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

// Animations
.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fade-in {
	animation-name: fadeIn;
}

// Breadcrumbs
.sc-breadcrumb {
	background-color: inherit;
	padding: 1rem 0;
	margin-bottom: 0;
	position: relative;
	z-index: 1;
	border-radius: 0;
	align-items: center;

	@media (max-width: $break-sm-max) {
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	.fa-home {
		display: block;

		&:before {
			display: none;
		}

		&:after {
			content: '';
			display: block;
			background: url(../img/sprite.svg#include--breadcrumb-home-icon) no-repeat;
			width: 16px;
			height: 16px;
		}
	}
}

.sc-breadcrumb-item {
	flex: 0 0 auto;
	display: flex;
	align-items: center;

	@media (max-width: $break-sm-max) {
		white-space: nowrap;
	}

	a {
		color: #28293D;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&:first-child {
		width: 16px;
		height: 16px;

		> * {
			width: 16px;
			height: 16px;
			display: block;
		}
	}

	&:last-child {
		color: $lightTextColor;
	}
}

.sc-breadcrumb-item + .sc-breadcrumb-item::before {
	content: '';
	background: url(../img/sprite.svg#include--breadcrumb-divider-icon) no-repeat;
	display: block;
	width: 6px;
	height: 6px;
	background-size: cover;
	padding-right: 6px;
	margin-right: 6px;
}

// Pagination
.pagination {
	justify-content: center;
	margin: 0 !important;
	flex-wrap: wrap;
	position: relative;
	z-index: 2;
	gap: 1rem;

	li {

		a,
		span {
			display: block;
			line-height: 1rem;
			padding: 0.75rem 1rem;
			background: #FAFAFC;
			box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
			0px 0.5px 2px rgba(96, 97, 112, 0.16);
			border-radius: 2px;
			color: $darkTextColor;
			transition: background $mainTransition, color $mainTransition;
			text-decoration: none;
		}

		&.active span,
		&:hover a {
			color: #FFF;
			background-color: $primaryColor;
		}
	}
}

// Tables
.table {
	margin-bottom: 0;
	border-left: 1px solid #f2f4f6;
	border-right: 1px solid #f2f4f6;
}

.table>:not(:first-child) {
	border-top: 1px solid #f2f2f5;
}

.table thead th {
	border: 0;
	font-weight: 400;
	font-size: 1rem;
	line-height: 20px;
	padding: 0.75rem 1rem;
	vertical-align: middle;
}

.table .thead-dark th {
	background-color: #F2F2F5;
	border: 1px solid #f2f5f5;
	color: $darkTextColor;
}

.table tbody {
	font-size: 14px;
	color: #555770;
}

.table td {
	border-color: #F2F2F5;
}

.table td,
.table th {
	vertical-align: top;
}

.table tbody tr:first-child td {
	border-top: 0;
}

.table td a {
	color: $linksColor;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

#oct-policy {
	padding: 1.5rem 0;
	transition: bottom 0.8s ease, opacity 0.8s ease;
	z-index: 10030;
	@include media-breakpoint-down(md) {
		bottom: 2.75rem;
	}

	&.hidden {
		bottom: -100%;
		opacity: 0;
	}

	.container {
		max-width: 1024px !important;
	}

	.content-block-p24 {
		box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
	}
}

.oct-policy-text {
	a {
		color: $linksColor;
		text-decoration: underline;

		&:hover {
			color: $linksColor;
			text-decoration: none;
		}
	}
}

// Video
.sc-video {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	background-color: #000000;
}

.sc-video__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.sc-video__media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}

.sc-video__button {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	display: none;
	padding: 0;
	width: 68px;
	height: 48px;
	border: none;
	background-color: transparent;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.sc-video__button-shape {
	fill: #212121;
	fill-opacity: 0.8;
}

.sc-video__button-icon {
	fill: #ffffff;
}

.sc-video__button:focus {
	outline: none;
}

.sc-video:hover .sc-video__button-shape,
.sc-video__button:focus .sc-video__button-shape {
	fill: #ff0000;
	fill-opacity: 1;
}

.sc-video--enabled {
	cursor: pointer;
}

.sc-video--enabled .sc-video__button {
	display: block;
}

.tooltip {
	z-index: 19999;
}

#oct-infobar.hidden {
	opacity: 0;
	display: none;
}

.sc-product-content,
.content-block,
.sc-blog-post-text {
	iframe {
		max-width: 100%;
	}
}

.slick-slide img {
	pointer-events: none;
}

.fancybox-button--thumbs {
	display: none !important;
}